<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="addDiscountModal"
    :title="$t(type) + ' ' + $t('Discount')"
    :buttons-hidden="true" autocomplete="nofill">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="code" :label-placeholder="$t('Code') + '*'" v-model="discount.code" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|decimal'" name="value" :label-placeholder="$t('Value') + '*'" v-model="discount.value" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('value')">{{ errors.first('value') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select class="mt-10 w-full" name="statement" v-model="statement" v-validate="'required'" :placeholder="$t('Statement')" label="number" :options="statements"/>
          <span class="text-danger text-sm" v-show="errors.has('statement')">{{ errors.first('statement') }}</span>
        </div>
        <div class="vx-col w-full">
          <vs-input v-validate="'required'" name="description"  :placeholder="$t('Description') + '*'" v-model="discount.description"  class="mt-8 w-full"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'Add'" @click="addDiscount" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updateDiscount" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
  </shipblu-prompt>
</template>

<script>
import vSelect from 'vue-select'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['addDiscountModal', 'type', 'discount', 'statements', 'statementsDic'],
  data () {
    return {
      statement: []
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  watch: {
    type () {
      if (this.type === 'Add') {
        this.statement = []
      }
    },
    discount () {
      if (this.type === 'Edit') {
        this.statement = []
        this.discount.statement = this.statement.push({
          id: this.discount.statement.id,
          number: this.statementsDic[this.discount.statement.id]
        })
      } else {
        this.statement = []
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('addDiscountModal', false)
      this.$emit('loadDiscounts')
    },
    addDiscount () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const discount = { 
            value: this.discount.value,
            code: this.discount.code,
            description: this.discount.description,
            statement: this.statement.id
          }
          if (!this.isOnline) this.$emit('addDiscountModal', false)
          sendRequest(false, false, this, 'api/v1/billing/discounts/', 'post', discount, true,
            () => {
              this.$emit('addDiscountModal', false)
              this.$emit('loadDiscounts')
            }
          )
        }
      })
    },
    updateDiscount () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const discount = { 
            value: this.discount.value,
            code: this.discount.code,
            description: this.discount.description,
            statement: this.statement.id
          }

          if (!this.isOnline) this.$emit('addDiscountModal', false)
          sendRequest(false, false, this, `api/v1/billing/discounts/${this.discount.id}/`, 'patch', discount, true,
            () => {
              this.$emit('addDiscountModal', false)
              this.$emit('loadDiscounts')
            }
          )
        }
      })
    }
  }
}
</script>