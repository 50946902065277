<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <h2>{{$t('Merchant Tax Profile')}}</h2>
        </div>
      </div>
      <shipblu-card class="p-5" :cardLoading="taxDataLoading" v-if="count > 0">
        <div class="grid grid-cols-2 gap-4">
          <div class="leading-tight col-span-1">
            <span class="text-darkgray text-base">{{ taxData.company && taxData.company.id ? $t('Legal Name') : $t('Full Name') }}:</span>
            <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.name : taxData.person.name}}</span>
          </div>
          <div class="leading-tight col-span-1">
            <span class="text-darkgray text-base">{{ taxData.company && taxData.company.id ? $t('Tax Registration Number') : $t('National ID') }}:</span>
            <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.tax_registration_number : taxData.person.national_id}}</span>
          </div>
          <div class="leading-tight col-span-1">
            <span class="text-darkgray text-base">{{$t('Governorate')}}:</span>
            <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.governorate : taxData.person.governorate}}</span>
          </div>
          <div class="leading-tight col-span-1">
            <span class="text-darkgray text-base">{{$t('City')}}:</span>
            <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.city : taxData.person.city}}</span>
          </div>
          <div class="leading-tight col-span-1">
            <span class="text-darkgray text-base">{{$t('Street')}}:</span>
            <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.street : taxData.person.street}}</span>
          </div>
          <div class="leading-tight col-span-1">
            <span class="text-darkgray text-base">{{$t('Building Number')}}:</span>
            <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.building_number : taxData.person.building_number}}</span>
          </div>
        </div>
      </shipblu-card>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      taxData: {},
      taxDataLoading: false,
      count: 0
    }
  },
  methods: {
    loadTaxProfile () {
      this.taxDataLoading = true
      sendRequest(true, false, this, `api/v1/accounting/tax-profiles/?merchant=${this.$route.params.merchantID}`, 'get', null, true,
        (response) => {
          this.taxData = response.data.results[0]
          this.count = response.data.count
          this.taxDataLoading = false
        }
      )
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    this.loadTaxProfile()
  }
}
</script>