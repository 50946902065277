<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="pickupPointModal"
    :title="$t(type)"
    :buttons-hidden="true" autocomplete="nofill">
      <div class="vx-row" v-if="type === 'Add PUDO'">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="name" :label-placeholder="$t('Name') + '*'" v-model="address.name" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="display name" :label-placeholder="$t('Display Name') + '*'" v-model="address.display_name" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('display name')">{{ errors.first('display name') }}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input v-validate="'required'" name="address 1" :label-placeholder="$t('Address 1') + '*'" v-model="address.address.line_1" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
        </div>
        <div class="vx-col w-full">
          <vs-input v-validate="'required'" name="address 2" :label-placeholder="$t('Address 2') + '*'" v-model="address.address.line_2" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
        </div>
        <div class="vx-col w-full">
          <vs-input name="address 2" :label-placeholder="$t('Address 3')" v-model="address.address.line_3" class="mt-8 w-full"/>
        </div>
        <div class="vx-col w-full" v-if="type === 'Add PUDO'">
          <vs-input v-validate="'url:require_protocol'" name="google map link" :label-placeholder="$t('Google Maps Link')" v-model="address.address.google_map_link" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('google map link')">{{ errors.first('google map link') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="governorate" v-model="address.governorate" v-validate="'required'" :placeholder="$t('Governorate') + '*'" label="name" :options="governorates"/>
          <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="city" v-model="address.city"
            v-validate="'required'" :placeholder="$t('City') + '*'" label="name" :options="cities"/>
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" v-model="address.address.zone" v-validate="'required'"
            name="zone" :placeholder="$t('Zone') + '*'" label="name" :options="zones"/>
          <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="addPickupPoint" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'

export default {
  props: [
    'pickupPointModal', 'type', 'address', 'governorates', 
    'cities', 'zones'
  ],
  data () {
    return {
    }
  },
  
  components: {
    ShipbluPrompt,
    vSelect
  },
  methods: {
    closeModal () {
      this.$emit('pickupPointModal', false)
    },
    addPickupPoint () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('addPickupPoint')
        }
      })
    }
  }
}
</script>