<template>
  <div id="page-user-view" class="p-6">
    <div class="w-full flex-1" id="account-info-col-1">
      <div class="w-full flex justify-end pr-6" v-if="$store.state.AppActiveUser.userRole === 'admin'">
        <vs-button @click="subscribeFulfillment" color="primary" size="medium" v-if="!merchantSubscribed">{{$t('Subscribe to Fulfillment')}}</vs-button>
      </div>
      <form :v-model="data" autocomplete="nofill">
      <shipblu-card class="mb-base mt-4 p-5" :cardLoading="dataLoading">
        <p class="h4 mb-6">{{$t('Merchant Information')}}</p>
        <div v-if="editable">
          <input type="file" @change="changeImage" class="hidden" ref="fileInput" accept="image/*">
          <div
            @click="$refs.fileInput.click()"
            @drop="handleDrop"
            @dragover="handleDragover"
            @dragenter="handleDragover"
            class="w-4/5 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
            <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
            <span>{{$t('Drop Image or')}} </span>
            <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
          </div>
        </div>
        <div v-else class="ml-auto mr-auto text-center justify-center">
          <img class="ml-auto mr-auto mb-4 h-24 w-24" :src="data.logo" />
        </div>
        <div class="vx-row">
          <div class="vx-col w-1/2 mt-4">
            <vs-input disabled="true" v-validate="'required'" :label-placeholder="$t('Legal Name')" name="legal name" v-model="data.name" class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('legal name')">{{ errors.first('legal name') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-4">
            <vs-input :disabled="!editable" :label-placeholder="$t('Merchant Display Name')" name="display name" v-model="data.display_name" class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('display name')">{{ errors.first('display name') }}</span>
          </div>
          <div class="vx-col w-1/3 mt-4">
            <vs-input :disabled="!editable" v-validate="'required|email'" name="email" :label-placeholder="$t('Email')" v-model="data.store_email" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
          <div class="vx-col w-1/3 mt-4">
           <vs-input  :disabled="!editable" v-validate="'required|url:require_protocol'" name="store url" :label-placeholder="$t('Store URL')" v-model="data.store_url" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('store url')">{{ errors.first('store url') }}</span>
          </div>
          <div class="vx-col w-1/3 mt-4">
            <vs-input :disabled="!editable" v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="store phone" :label-placeholder="$t('Store Phone')" v-model="data.store_phone" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('store phone')">{{ errors.first('store phone') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-4">
            <v-select class="mt-2 w-full" name="status" v-model="data.status" item-text="name" item-value="name" :placeholder="$t('Status')" :disabled="!editable" v-validate="'required'" label="name" :options="statues"/>
            <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first('status') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-4">
           <v-select autocomplete="nofill" :options="governorates" class="mt-2 w-full" name="governorate" label="name" item-text="name"
            v-model="governorate" item-value="id" return-object v-on:input="governorate ? loadCities(`${governorate.id}`) : zones = [], zone = '', cities = [], city = ''"
            :placeholder="$t('Governorate')" :disabled="!editable" v-validate="'required'"></v-select>
            <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-4">
            <v-select autocomplete="nofill" :options="cities" class="mt-2 w-full" name="city" label="name" item-text="name"
              v-model="city" item-value="id" return-object v-on:input="city ? loadZones(`${city.id}`) : zones = [], zone = ''"
              :placeholder="$t('City')" :disabled="!editable" v-validate="'required'"></v-select>
            <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-4">
            <v-select autocomplete="nofill" :options="zones" class="mt-2 w-full" name="zone" label="name" item-text="name"
              v-model="zone" item-value="id" return-object :placeholder="$t('Zone')" :disabled="!editable" v-validate="'required'"></v-select>
            <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
          </div>

          <div class="vx-col w-1/2 mt-4">
            <v-select :disabled="!editable" @search="handleAccountManagersSearch" @keyup.enter.native="handleAccountManagersSearch" search autocomplete="nofill" class="select-large mt-10 w-full" name="account manager"
            v-model="data.account_manager" :placeholder="$t('Account Manager')" label="name" :options="accountManagers"/>
          </div>
          <div class="vx-col w-1/2 mt-4">
            <v-select :disabled="!editable && !salesEditable" @search="handleSalesSearch" @keyup.enter.native="handleSalesSearch" search autocomplete="nofill" class="select-large mt-10 w-full" name="sales"
            v-model="data.sales" :placeholder="$t('Sales')" label="name" :options="sales"/>
          </div>
          <div class="vx-col w-full mt-4">
            <vs-input :disabled="!editable" v-validate="'required'" name="address 1" :label-placeholder="$t('Address 1')" v-model="data.address.line_1" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
          </div>
          <div class="vx-col w-full mt-4">
            <vs-input :disabled="!editable" v-validate="'required'" name="address 2" :label-placeholder="$t('Address 2')" v-model="data.address.line_2" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
          </div>
          <div class="vx-col w-full mt-4">
            <vs-input name="address 2" :disabled="!editable" :label-placeholder="$t('Address 3')" v-model="data.address.line_3" class="w-full"/>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col">
            <h4>{{$t('Industry')}}</h4>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-1/3 mt-2">
            <label class="font-semibold">{{$t('Industry')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <v-select :disabled="!editable" autocomplete="nofill" class="mt-2 w-full" v-model="data.industry" v-validate="'required'"
              name="industry" :placeholder="$t('industries')" label="text" :options="industries"/>
            <span class="text-danger text-sm" v-show="errors.has('industries')">{{ errors.first('industries') }}</span>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col">
            <h4>{{$t('Subscription')}}</h4>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-1/3 mt-2">
            <label class="font-semibold">{{$t('Subscription')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <v-select :disabled="!editable" autocomplete="nofill" class="mt-2 w-full" v-model="data.subscription" v-validate="'required'"
              name="subscription" :placeholder="$t('Subscription')" label="name" :options="subscriptions"/>
            <span class="text-danger text-sm" v-show="errors.has('subscription')">{{ errors.first('subscription') }}</span>
          </div>
        </div>
        <div class="vx-row mb-2" v-if="data.subscription && data.subscription.id === 2">
          <div class="vx-col w-1/3" v-for="day in days" :key="day.index">
            <vs-checkbox v-validate="'required'" :disabled="!editable" class="mb-2" name="days" v-model="day.pickup" :vs-value="day">{{day.day}}</vs-checkbox>
          </div>
          <span class="text-danger text-sm block" v-show="errors.has('days')">{{errors.first('days')}}</span>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-1/3 mt-2">
            <label class="font-semibold">{{$t('Committed Volume')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input :disabled="!editable" v-validate="'required|numeric'" :placeholder="$t('Committed Volume')" name="committed volume" v-model="data.committed_volume" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('committed volume')">{{ errors.first('committed volume') }}</span>
          </div>
        </div>
        <div v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support'" class="vx-row mb-5 mt-10">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Pickup Time')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-radio class="mr-4" :disabled="!editable" v-model="data.pickup_time" vs-value="1">{{$t('1 pm')}}</vs-radio>
            <vs-radio :disabled="!editable" v-model="data.pickup_time" vs-value="2">{{$t('3 pm')}}</vs-radio>
          </div>
        </div>
        <div class="vx-row mb-5 mt-10">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Automatic Pickup, Return, Collection Request')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-switch v-model="data.auto_process_orders" :disabled="!editable"/>
          </div>
        </div>
        <div class="vx-row mb-5 mt-10" v-if="merchantSubscribed === true">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Auto Process Outbound Orders')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-switch v-model="autoProcessOutboundOrders" :disabled="!editable"/>
          </div>
        </div>
        <div class="vx-row mb-5 mt-10" v-if="merchantSubscribed === true">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Auto Process Inbound Orders')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-switch v-model="autoProcessInboundOrders" :disabled="!editable"/>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-1/3 mt-2">
            <label class="font-semibold">{{$t('Billing Days')}}</label>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3" v-for="day in billingDays" :key="day.index">
            <vs-checkbox v-validate="'required'" :disabled="!editBilling" class="mb-2" name="billing days" v-model="day.billing" :vs-value="day">{{day.day}}</vs-checkbox>
          </div>
          <span class="text-danger text-sm block" v-show="errors.has('billing days')">{{errors.first('billing days')}}</span>
        </div>
        <div class="flex flex-wrap items-center justify-end mt-6" v-if="$store.state.AppActiveUser.userRole === 'sales'">
          <vs-button class="ml-auto" v-if="!salesEditable" @click="salesEditable = true">{{$t('Edit Sales')}}</vs-button>
          <vs-button class="ml-auto" v-if="salesEditable" @click="updateMerchantSales()">{{$t('Save')}}</vs-button>
          <vs-button class="ml-4" v-if="salesEditable" @click="cancelEditSales()" type="border" color="warning">{{$t('Cancel')}}</vs-button>
        </div>
        <div class="flex flex-wrap items-center justify-end mt-6" v-if="$route.name === 'admin-merchant-view' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'finance'">
          <vs-button class="ml-auto mt-2" v-if="!editable && !editBilling" @click="editInfo()">{{$t('Edit')}}</vs-button>
          <vs-button class="ml-auto mt-2" v-if="editable || editBilling" @click="updateMerchant()">{{$t('Save')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="editable || editBilling" @click="cancelEdit()" type="border" color="warning">{{$t('Cancel')}}</vs-button>
        </div>
      </shipblu-card>
      </form>
    </div>
  </div>
</template>

<script>
import {sendFulfillmentRequest, sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import common from '@/assets/utils/common'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      autoProcessOutboundOrders: false,
      autoProcessInboundOrders: false,
      data : {
        name: '',
        pickup_days: [],
        subscription: {},
        address: {
          zone: {
            city: {
              governorate: {
                name: ''
              }
            }
          }
        }
      },
      industriesDic: {
        'mixed' : 'Mixed',
        'coffee_beans_oil_honey_herbs': 'Coffee, Beans, Oil, Honey & Herbs',
        'accessories' : 'Accessories',
        'mobile_laptop_accessories' : 'Mobile & Laptop Accessories',
        'home_accessories' : 'Home Accessories',
        'apparel_fashion_and_clothes' : 'Apparel, Fashion and Clothes',
        'pharmaceutical_medicine_and_supplements' : 'Pharmaceutical Medicine, and supplements',
        'beauty_cosmetics_perfumes_personal_care' : 'Beauty Cosmetics, Perfumes & Personal Care',
        'detergent_cleaners' : 'Detergent and Cleaners',
        'jewelry_expensive_collectibles' : 'Jewelry & Expensive Collectibles',
        'car_motorcycle_accessories' : 'Car & Motorcycle Accessories',
        'electronics': 'Electronics',
        'gifts_printed_materials' : 'Gifts & Printed Materials',
        'eyeglasses_contact_lenses' : 'Eyeglasses and Contact Lenses',
        'vending_coffee_food_machines' : 'Vending, Coffee & Food Machines',
        'books': 'Books',
        'pet_supplies_accessories' : 'Pet Supplies & Accessories',
        'bags_packs_bottles_flasks': 'Bags, Packs, Bottles & Flasks',
        'documents'  : 'Documents',
        'woodworking' : 'Woodworking',
        'affiliate' : 'Affiliate',
        'fulfillment' : 'Fulfillment',
        'pharmaceutical' : 'Pharmaceutical',
        'marketplace' : 'Marketplace',
        'mobile_accessories' : 'Mobile Accessories',
        'handmade_products' : 'Handmade Products'
      },
      industries: [],
      dataLoading: false,
      governorates: [],
      cities: [],
      zones: [],
      editable: false,
      salesEditable: false,
      statues: ['on_hold', 'active'],
      subscriptions: [],
      days: [],
      billingDays: [],
      governorate: {},
      city: {},
      zone: {},
      file_url: '',
      merchantID: '',
      accountManagers: [],
      sales: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      merchantSubscribed: false,
      editBilling: false
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  components: {
    vSelect,
    ShipbluCard
  },
  watch: {
    '$route.params.lang' () {
      this.industries = common.getIndustries(this)
      this.data.industry = i18nData[this.$i18n.locale][this.industriesDic[this.data.industry]]
    },
    'searchVal' (val) {
      if (val === '') {
        this.accountManagers = []
        this.sales = []
      }
    }
  },
  methods: {
    editInfo () {
      switch (this.$store.state.AppActiveUser.userRole) {
      case 'admin':
        this.editBilling = true
        this.editable = true
        break
      case 'finance':
        this.editBilling = true
        break
      default:
        this.editable = true
        break
      }
    },
    changeImage () {
      const formData = new FormData()
      formData.append('old_file', this.data.logo.split('.com/')[1])
      formData.append('new_file', this.$refs.fileInput.files[0])
      sendRequest(false, false, this, 'api/v1/upload-file/', 'patch', formData, true,
        (response) => {
          this.file_url = response.data.file_url
        }
      )
    },
    handleAccountManagersSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadAccountManagersSearch)
    },
    loadAccountManagersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadAccountManagers()
      }
    },
    loadAccountManagers () {
      this.searchInProgress = true
      const query = `?search=${this.searchVal}`
      sendRequest(false, false, this, `api/v1/users/customers-support/${query}`, 'get', null, true,
        (response) => {
          response.data.results.forEach(item => {
            item.name = `${item.first_name} ${item.last_name}`
            this.accountManagers.push(item)
          })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    handleSalesSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadSalesSearch)
    },
    loadSalesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadSales()
      }
    },
    loadSales () {
      this.searchInProgress = true
      const query = `?search=${this.searchVal}`
      sendRequest(false, false, this, `api/v1/users/sales/${query}`, 'get', null, true,
        (response) => {
          response.data.results.forEach(item => {
            item.name = `${item.first_name} ${item.last_name}`
            this.sales.push(item)
          })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadSubscriptions () {
      sendRequest(false, false, this, 'api/v1/subscriptions/', 'get', null, true,
        (response) => {
          this.subscriptions = response.data
          this.loadSubscriptionsDays()
        }
      )
    },
    loadSubscriptionsDays () {
      sendRequest(false, false, this, 'api/v1/subscriptions/pickup-days/', 'get', null, true,
        (response) => {
          this.days = response.data
          this.billingDays = response.data
          const pickupDays = this.data.pickup_days.map(element => element)
          this.days.forEach(element => {
            if (pickupDays.includes(element.id)) {
              element.pickup = true
            } else {
              element.pickup = false
            }
          })

          const billingDays = this.data.billing_days.map(element => element)
          this.billingDays.forEach(element => {
            if (billingDays.includes(element.id)) {
              element.billing = true
            } else {
              element.billing = false
            }
          })
        }
      )
    },
    loadMerchant (merchantID) {
      this.dataLoading = true
      sendRequest(true, false, this, `api/v1/merchants/${merchantID}/`, 'get', null, true,
        (response) => {
          this.loadSubscriptions()
          this.data = response.data
          this.data.industry = i18nData[this.$i18n.locale][this.industriesDic[this.data.industry]]
          if (this.data.address.zone) {
            this.governorate = {
              id: this.data.address.zone.city.governorate.id,
              name: this.data.address.zone.city.governorate.name
            }
            this.city = {
              id: this.data.address.zone.city.id,
              name: this.data.address.zone.city.name
            }
            this.zone = {
              id: this.data.address.zone.id,
              name: this.data.address.zone.name
            }
          }
          if (this.$store.state.AppActiveUser.userRole === 'commercial-admin') {
            this.dataLoading = false
          } else {
            this.loadGovernorates()
          }
          this.data.sales.name = this.data.sales ? `${this.data.sales.first_name} ${this.data.sales.last_name}` : ''
          this.data.account_manager.name = this.data.account_manager ? `${this.data.account_manager.first_name} ${this.data.account_manager.last_name}` : ''
        }
      )
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
        if (this.governorate && this.governorate.id) {
          this.loadCities(this.governorate.id)
        } else {
          this.governorate = ''
          this.city = ''
          this.zone = ''
          this.dataLoading = false
        }
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
        if (this.city) {
          this.loadZones(this.city.id)
        }
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
      this.dataLoading = false
    },
    updateMerchantSales () {
      this.$vs.loading()
      const merchantData = {
        sales: this.data.sales ? this.data.sales.id : null
      }
      sendRequest(false, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'patch', merchantData, true,
        () => {
          this.salesEditable = !this.salesEditable
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Merchant'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadMerchant(this.$route.params.merchantID)
        }
      )
    },
    updateMerchant () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const pickupDaysIDs = []
          const billingDaysIDs = []
          this.days.forEach(element => {
            if (element.pickup) {
              pickupDaysIDs.push(element.id)
            }
          })
          this.billingDays.forEach(element => {
            if (element.billing) {
              billingDaysIDs.push(element.id)
            }
          })
          this.$vs.loading()
          const merchantData = {
            industry: this.data.industry.value,
            subscription: this.data.subscription.id,
            pickup_days: pickupDaysIDs,
            billing_days: billingDaysIDs,
            committed_volume: this.data.committed_volume,
            store_url: this.data.store_url,
            display_name: this.data.display_name,
            store_phone: this.data.store_phone,
            store_email: this.data.store_email,
            status: this.data.status,
            account_manager: this.data.account_manager ? this.data.account_manager.id : null,
            sales: this.data.sales ? this.data.sales.id : null,
            auto_process_orders: this.data.auto_process_orders,
            address: {
              line_1: this.data.address.line_1,
              line_2: this.data.address.line_2,
              line_3: this.data.address.line_3,
              zone: this.zone.id
            },
            pickup_time: this.data.pickup_time ? Number(this.data.pickup_time) : 1
          }
          if (this.data.subscription.id === 1) {
            delete merchantData.pickup_days
          }
          if (this.file_url !== '') {
            merchantData.logo = this.file_url
          }
          sendRequest(false, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'patch', merchantData, true,
            () => {
              if (this.merchantSubscribed) {
                this.updateMerchantFulfillment()
              }
              this.editable = false
              this.editBilling = false
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Merchant'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.loadMerchant(this.$route.params.merchantID)
            }
          )
        }
      })
    },
    updateMerchantFulfillment () {
      const merchantData = {
        auto_process_inbound_orders: this.autoProcessInboundOrders,
        auto_process_outbound_orders:this.autoProcessOutboundOrders
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/merchants/${this.$route.params.merchantID}/`, 'patch', merchantData, true, 
        () => {
          this.loadMerchantFulfillment()
        })
    },
    cancelEdit () {
      this.editBilling = false
      this.editable = false
      this.loadMerchant(this.$route.params.merchantID)
    },
    cancelEditSales () {
      this.salesEditable = !this.salesEditable
      this.loadMerchant(this.$route.params.merchantID)
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isImage(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .png, .jpg suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
    },
    isImage (file) {
      return /\.(png|jpg)$/.test(file.name)
    },
    loadMerchantFulfillment () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/merchants/${this.$route.params.merchantID}/`, 'get', null, true,
        (response) => {
          this.autoProcessInboundOrders = response.data.auto_process_inbound_orders
          this.autoProcessOutboundOrders = response.data.auto_process_outbound_orders
          this.merchantSubscribed = true
        }, (error) => {
          if (error && error.response && error.response.status && error.response.status === 404) {
            this.merchantSubscribed = false
          }
        })
    },
    subscribeFulfillment () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/merchants/${this.$route.params.merchantID}/`, 'post', {}, true,
        () => {
          this.loadMerchantFulfillment()
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Merchant'] + i18nData[this.$i18n.locale][' has been subscribed successfully!'],
            position: 'top-center'
          })
        })
    }
  },
  created () {
    this.industries = common.getIndustries(this)
    this.merchantID = this.$route.params.merchantID
    this.loadMerchant(this.merchantID)
    this.loadMerchantFulfillment()
  }
}
</script>