<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <h2>{{$t('Discounts')}}</h2>
          </div>
          <div v-if="$store.state.AppActiveUser.userRole !== 'sales'" class="vx-col w-full lg:w-1/2 items-end">
            <div class="flex justify-between">
              <span class="font-semibold"></span>
              <span class="font-medium text-primary cursor-pointer">
                <vs-button
                  @click="newDiscount('Add')"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-plus"
                  size="large"
                >
                </vs-button>
              </span>
            </div>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="discounts"
          :tableLoader="tableLoader"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('ID')}}</shipblu-th>
            <shipblu-th v-if="$route.name === 'finance-accounts-discounts'">{{$t('Merchant Name')}}</shipblu-th>
            <shipblu-th>{{$t('Code')}}</shipblu-th>
            <shipblu-th>{{$t('Value')}}</shipblu-th>
            <shipblu-th>{{$t('Description')}}</shipblu-th>
            <shipblu-th>{{$t('Statement')}}</shipblu-th>
            <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'sales'">{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </shipblu-td>

              <shipblu-td v-if="$route.name === 'finance-accounts-discounts'" :data="data[indextr].merchantName">
                {{ data[indextr].statement && data[indextr].statement.merchant ? data[indextr].statement.merchant.name : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].code">
                {{ data[indextr].code }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].value">
                {{ data[indextr].value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].description">
                {{ data[indextr].description }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].statement">
                {{ data[indextr].statement ? data[indextr].statement.number : ''}}
              </shipblu-td>

              <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'sales'">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-more-horizontal"
                    class="mr-2"
                  >
                  </vs-button>
                  <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item @click="newDiscount('Edit', data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="EditIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Edit')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="deleteDiscount(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="TrashIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Delete')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />

    <discounts :type="type" :addDiscountModal="addDiscountModal" :statements="statements" :statementsDic="statementsDic"
    @statement="statement = $event" @addDiscountModal="addDiscountModal = $event" :discount="discount" @loadDiscounts="loadDiscounts"></discounts>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import Discounts from './Discounts.vue'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      discounts: [],
      selected: [],
      deleteData: {},
      addDiscountModal: false,
      type: '',
      discount: {
        value: '',
        description: '',
        code: ''
      },
      merchantID: '',
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      statements: [],
      file_URl: '',
      file_key: '',
      merchants: [],
      statementsDic: {},
      tableLoader: false,
      statement: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadDiscounts()
    }
  },
  methods: {
    loadStatements () {
      sendRequest(false, false, this, `api/v1/billing/statements/?merchant=${this.$route.params.merchantID ? this.$route.params.merchantID : ''}&limit=1000`, 'get', null, true,
        (response) => {
          this.statements = response.data.results
          this.statements.forEach(element => {
            this.statementsDic[element.id] = element.number
          })
        }
      )
    },
    loadDiscounts () {
      this.tableLoader = true
      const query = `?statement__merchant=${this.$route.params.merchantID ? this.$route.params.merchantID  : ''}&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/discounts/${query}`, 'get', null, true,
        (response) => {
          this.discounts = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    newDiscount (type, data) {
      this.type = type
      if (type === 'Add') {
        this.discount = {
          value: '',
          description: '',
          code: ''
        }
      } else {
        this.discount = data
      }
      this.addDiscountModal = true
    },
    deleteDiscount (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/discounts/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadDiscounts()
        }
      )
    }
  },
  components: {
    Discounts,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
    this.loadDiscounts()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
