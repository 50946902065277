<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="statementModal"
    :title="$t(type) + ' ' + $t('Statement')"
    :buttons-hidden="true" autocomplete="nofill">
      <div>
        <input name="file" type="file" @change="changeImage" class="hidden" ref="fileInput">
        <div
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="w-4/5 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <div v-if="image === ''">
            <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
            <span>{{$t('Drop File or')}} </span>
            <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
          </div>
          <div v-else>
            <img class="w-20 h-20" :src="image"/>
          </div>
        </div>
        <span class="text-danger text-sm" v-show="!image">The file field is required</span>
      </div>
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" :disabled="type === 'Edit'" name="number" :label-placeholder="$t('Statement Number') + '*'" v-model="statement.number" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('number')">{{ errors.first('number') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="net service fees due" :label-placeholder="$t('Net Service Fees Due') + '*'" v-model="statement.net_service_fees_due" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('net_service_fees_due')">{{ errors.first('net service fees due') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="net cod due" :label-placeholder="$t('Net COD Due') + '*'" v-model="statement.net_cod_due" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('net cod due')">{{ errors.first('net cod due') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <datepicker v-validate="'required'" name="expected net cod deposit date"  :placeholder="$t('Expected Deposit Date') + '*'" v-model="statement.expected_net_cod_deposit_date"  class="mt-8 w-full"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('expected net cod deposit date')">{{ errors.first('expected net cod deposit date') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-if="type === 'Edit'" v-validate="'required|decimal'" name="opening balance" :label-placeholder="$t('Opening Balance') + '*'" v-model="statement.opening_balance" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('opening balance')">{{ errors.first('opening balance') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="status" v-model="statement.status" 
          v-validate="'required'" :placeholder="$t('Status') + '*'" label="status" :options="allStatus"/>
          <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="accounting status" v-model="statement.accounting_status" 
          v-validate="'required'" :placeholder="$t('Accounting Status') + '*'" label="status" :options="accountStatus"/>
          <span class="text-danger text-sm" v-show="errors.has('accounting status')">{{ errors.first('accounting status') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'Add'" @click="addStatement" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updateStatement" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
  </shipblu-prompt>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['statementModal', 'type', 'statement', 'statements'],
  data () {
    return {
      allStatus: ['created', 'ready'],
      accountStatus: ['payment_due', 'fully_paid'],
      image: '',
      file_url: ''
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    Datepicker
  },
  watch: {
    'statement.expected_net_cod_deposit_date' (val) {
      this.statement.expected_net_cod_deposit_date = common.formatDate(val)
    },
    type () {
      if (this.type === 'Edit') {
        this.image = this.statement.pdf_link
      } else {
        this.image = ''
      }
    }
  },
  methods: {
    changeImage () {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.image = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', this.$refs.fileInput.files[0])
      sendRequest(false, false, this, 'api/v1/upload-file/', 'post', formData, true,
        (response) => {
          this.file_url = response.data.file_url
          this.$emit('file_url', this.file_url)
        }
      )
    },
    closeModal () {
      this.$emit('statementModal', false)
      this.$emit('loadStatements')
    },
    addStatement () {
      this.$validator.validateAll().then(result => {
        if (result && this.image) {
          this.$emit('addStatement')
          this.image = ''
        }
      })
    },
    updateStatement () {
      this.$validator.validateAll().then(result => {
        if (result && this.image) {
          this.$emit('file_url', this.file_url ? this.file_url : this.statement.pdf_link)
          this.$emit('updateStatement')
        }
      })
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  }
}
</script>