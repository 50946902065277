<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <h2>{{$t('Merchant Bank Deposits')}}</h2>
          </div>
          <div v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'finance'" class="vx-col w-full lg:w-1/2 items-end">
            <div class="flex justify-between">
              <span class="font-semibold"></span>
              <span class="font-medium text-primary cursor-pointer">
                <vs-button
                  @click="newBankDeposit('Add')"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-plus"
                  size="large"
                >
                </vs-button>
              </span>
            </div>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="bankDeposits"
          :tableLoader="tableLoader"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('ID')}}</shipblu-th>
            <shipblu-th>{{$t('Bank Reference Number')}}</shipblu-th>
            <shipblu-th>{{$t('Amount')}}</shipblu-th>
            <shipblu-th>{{$t('Deposited On')}}</shipblu-th>
            <shipblu-th>{{$t('Deposit Slip')}}</shipblu-th>
            <shipblu-th>{{$t('Statement')}}</shipblu-th>
            <shipblu-th>{{$t('Status')}}</shipblu-th>
            <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'">{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].bank_reference_number">
                {{ data[indextr].bank_reference_number }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].amount">
                {{ (data[indextr].amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].deposit_date">
                {{ data[indextr].deposit_date }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].link_to_deposit_slip">
                <a @click="downloadBankDeposit(data[indextr])" target="_blank">{{$t('Download')}}</a>
              </shipblu-td>

              <shipblu-td :data="data[indextr].statement">
                {{ data[indextr].statement.number }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </shipblu-td>

              <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-more-horizontal"
                    class="mr-2"
                  >
                  </vs-button>
                  <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="newBankDeposit('Edit', data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="EditIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Edit')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="deletePickupPoint(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="TrashIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Delete')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-bank-deposit :type="type" :bankDepositModal="bankDepositModal" :statements="statements"
    @bankDepositModal="bankDepositModal = $event" :bankDeposit="bankDeposit" @loadBankDeposits="loadBankDeposits"
    ></add-bank-deposit>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import AddBankDeposit from './AddBankDeposit.vue'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import common from '../../../assets/utils/common.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      bankDeposits: [],
      selected: [],
      pickupPoint: '',
      deleteData: {},
      bankDepositModal: false,
      type: 'Add Pickup Point',
      bankDeposit: {
        bank_reference_number: '',
        number: '',
        amount: ''
      },
      merchantID: '',
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      statements: [],
      file_URl: '',
      tableLoader: false,
      file_key: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadBankDeposits()
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadStatements () {
      sendRequest(false, false, this, `api/v1/billing/statements/?merchant=${this.$route.params.merchantID}&limit=100`, 'get', null, true,
        (response) => {
          this.statements = response.data.results
        }
      )
    },
    loadBankDeposits () {
      this.tableLoader = true
      const query = `?statement__merchant=${this.$route.params.merchantID}&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/bank-deposits/${query}`, 'get', null, true,
        (response) => {
          this.bankDeposits = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    downloadBankDeposit (data) {
      sendRequest(false, false, this, `api/v1/billing/bank-deposits/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    newBankDeposit (type, data) {
      this.type = type
      if (type === 'Add') {
        this.bankDeposit = {
          bank_reference_number: '',
          amount: '',
          deposit_slip_key: '',
          deposit_date: '',
          statement: ''
        }
      } else {
        this.bankDeposit = data
      }
      this.bankDepositModal = true
    },
    deletePickupPoint (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/bank-deposits/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadBankDeposits()
        }
      )
    }
  },
  components: { 
    AddBankDeposit,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
    this.loadBankDeposits()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
