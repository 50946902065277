<template>
  <div id="page-user-view">
    <div class="sm:flex relative block rounded-lg bg-primary p-4">
      <div class="sm:w-1/3 w-full sm:mt-0 mt-1 p-1">
        <div class="flex ">
          <img src="@/assets/images/pages/dollar.png" width="27" height="22">
          <div class="w-full">
            <div class="flex pl-3">
              <p class="font-medium text-l text-white">{{$t('Cash on the way')}}</p>
              <vx-tooltip boxShadow="0px 2px 10px rgba(28, 91, 254, 0.47)" textColor="#1C5BFE" color="#fff" :text="`${$t('The cash amount we will transfer to you')}`">
                <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
              </vx-tooltip>
            </div>
            <div class="rounded-full summary-cash px-4 py-1 mt-2">
              <p class="font-medium text-white"> 
                <span class="text-2xl" v-if="summaryCashamount.cash_on_the_way">{{ summaryCashamount.cash_on_the_way ? summaryCashamount.cash_on_the_way.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}</span>
                <span class="text-2xl" v-else>{{ '0.00' }} </span>
                <span class="text-l">{{$t('EGP')}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:w-1/3 p-1 w-full sm:pt-0 pt-8">
        <div class="flex ">
          <img src="@/assets/images/pages/Vector.svg" width="22" height="22">
          <div class="w-full">
            <div class="flex pl-3">
              <p class="font-medium text-l text-grey-light">{{$t('Collected & not yet transfered')}}</p>
              <vx-tooltip boxShadow="0px 2px 10px rgba(28, 91, 254, 0.47)" textColor="#1C5BFE" color="#fff" :text="`${$t('The cash we collected but not yet processed into a statement')}`">
                <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
              </vx-tooltip>
            </div>
            <p class="text-base font-medium mt-2 text-grey-light">
              <span class="text-2xl" v-if="summaryCashamount.cash_processing">{{ summaryCashamount.cash_processing ? summaryCashamount.cash_processing.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}</span>
              <span class="text-2xl" v-else>0.00 </span>
              <span class="text-l">{{$t('EGP')}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="sm:absolute right-0 bottom-0 text-right">
        <img src="@/assets/images/pages/statment-pay.png" width="210" height="150" class="mb">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['summaryCashamount'],
  data () {
    return {}
  }
}
</script>

<style lang="scss">
.summary-cash {
  border: 1px solid #FFFFFF !important;
  background-color: #4579fe;
  max-width: fit-content;
}
.mb {
  margin-bottom: -14px;
  @media(max-width: 576px) {
    margin-bottom: -28px;
  }
}
</style>