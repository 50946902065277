<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Instant Payouts')}}</h2>
      <div v-if="$store.state.AppActiveUser.userRole === 'finance'">
        <vs-dropdown
          vs-trigger-click
          class="dropdown dd-actions cursor-pointer mr-4"
        >
          <vs-button
            color="primary"
            icon-pack="feather"
            icon="icon-plus"
            size="large"
          >
          </vs-button>
          <vs-dropdown-menu class="add-shipment-dropdown w-40">
            <vs-dropdown-item @click="uploadInstantPayouts()">
              <span class="flex items-center">
                <feather-icon
                  icon="UploadIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Upload')}}</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </div>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="payoutData"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Payout Date')}}</shipblu-th>
        <shipblu-th>{{$t('Payout Method')}}</shipblu-th>
        <shipblu-th>{{$t('Payout Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Download')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'finance'">{{$t('Actions')}}</shipblu-th>
      </template>
      <template slot-scope="{ data }">
        <shipblu-tr class="inactive-link" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
          <shipblu-td :data="data[indextr].number">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].number">
            {{ data[indextr].payout_date }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].payout">
            <template v-if="data[indextr].payout_method">
              <span class="capitalize">Type: {{data[indextr].payout_method.bank ? 'Bank' : 'Wallet'}}</span><br/>
              <span v-if="data[indextr].payout_method.bank">Name: {{data[indextr].payout_method.bank.name}}</span><br/>
              <span>Number: {{data[indextr].payout_method.bank ? data[indextr].payout_method.bank.account_number : data[indextr].payout_method.wallet.number}}</span><br/>
            </template>
          </shipblu-td>
          <shipblu-td :data="data[indextr].net_cod_due">
            {{ Number(data[indextr].payout_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr].v2 ? data[indextr].v2 : data[indextr])) }}</span>
            </div>
          </shipblu-td>
          <shipblu-td :data="data[indextr].pdf_link" class="active-link">
            <a @click="downloadInstantPayout(data[indextr])" target="_blank" class="hover:underline">{{$t('PDF')}}</a> /
            <a @click="downloadExcelInstantPayout(data[indextr])" target="_blank" class="hover:underline">{{$t('Excel')}}</a>
          </shipblu-td>
          <shipblu-td class="active-link" v-if="$store.state.AppActiveUser.userRole === 'finance'">
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">
                <vs-dropdown-item @click="newStatement(data[indextr])">
                  <span class="flex items-center">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Edit')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <upload-bank-deposits :file_URl="file_URl" :uploadModal="uploadModal" @uploadModal="uploadModal = $event"/>
    <update-payout @loadPayouts="loadPayouts" :payoutModal="payoutModal" @payoutModal="payoutModal = $event" :payout="payout" ></update-payout>
  </div>
</template>
<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import UploadBankDeposits from './components/UploadBankDeposits.vue'
import UpdatePayout from './components/UpdatePayout.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      tabs: ['Needs Review', 'All'],
      tabsDic: {'0': 'Needs Review', '1': 'All'},
      tabsNameDic: {'Needs Review': 0, 'All': 1},
      payoutData: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      file_URl: '',
      tableLoader: false,
      selected: [],
      uploadModal: false,
      payoutModal: false,
      payout: {}
    }
  },
  watch: {
    '$route.query.tab' () {
      this.currentPage = 1
      this.loadPayouts()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPayouts()
    }
  },
  methods: {
    newStatement (data) {
      this.payout = data
      this.payoutModal = true
    },
    uploadInstantPayouts () {
      clearInterval(this.closeInterval)
      this.uploadModal = true
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadPayouts()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    loadPayouts () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&merchant=${this.$route.params.merchantID ? this.$route.params.merchantID : ''}`
      sendRequest(true, false, this, `api/v1/instant/payouts/${query}`, 'get', null, true,
        (response) => {
          this.payoutData = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
        }
      )
    },
    downloadInstantPayout (data) {
      sendRequest(false, false, this, `api/v1/instant/payouts/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelInstantPayout (data) {
      sendRequest(false, false, this, `api/v1/instant/payouts/${data.id}/excel/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    ShipbluPagination,
    UploadBankDeposits,
    UpdatePayout
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPayouts()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>