<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Returns')}}</h2>
      </div>
    </div>
    <table-filter :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <export-button class="mr-4 float-left whitespace-nowrap"></export-button>
      <cancel-shipments-button class="mr-4 float-left whitespace-nowrap" v-if="$store.state.AppActiveUser.userRole !== 'sales'" :selected="selected" @selected="selected = $event" @loadShipments="loadMerchantShipments"></cancel-shipments-button>
    </div>
    <shipblu-table
     :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @searchDate="handleSearchDate"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      searchDate
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th >{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Info')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Phone')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('CoD')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Delivered On')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-returns`, params: { type: 'returns', orderID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone">
            {{ data[indextr].customer.phone }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].packages">
            {{ data[indextr].packages.length }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].packages">
            <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].cashOnDelivery">
            {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].pickupDate">
            {{ data[indextr].pickup_date? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center py-4 justify-center font-semibold" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].deliveredOn">
            {{ data[indextr].returned_date && data[indextr].status === 'returned' ? new Date(data[indextr].returned_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ExportButton from '../../headOfFleet/components/ExportButton.vue'
import CancelShipmentsButton from '../components/CancelShipmentsButton.vue'
import TableFilter from '../../merchant/components/TableFilter.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'return requested',
          value: 'return_requested'
        },
        {
          name: 'return attempted',
          value: 'return_attempted'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'in transit & en route',
          value: 'in_transit, en_route'
        },
        {
          name: 'out for return',
          value: 'out_for_return'
        },
        {
          name: 'On Hold',
          value: 'on_hold'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      filters: [],
      merchant: {},
      searchVal: '',
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchInProgress: false,
      searchedValue: ' ',
      fromDate: '',
      toDate: '',
      tableLoader: false,
      criteria: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadMerchantShipments()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadMerchantShipments()
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadMerchantShipments () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?merchant=${this.$route.params.merchantID}&offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&${this.filters.includes('on_hold') ? 'on_hold=true' : `status=${this.filters}`}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/returns/${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.loadMerchantShipments()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadMerchantShipments()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all' || (this.filterValue === 'on_hold' && this.filters.includes('on_hold'))) {
        this.filters = []
        if (this.filterValue === 'on_hold' && !this.filters.includes('on_hold')) {
          this.filters.push('on_hold')
        }
      } else if (this.filters.includes('on_hold')) {
        this.filters.splice(this.filters.indexOf('on_hold'), 1)
      }
      this.selected = []
      this.currentPage = 1
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadMerchantShipments()
    }
  },
  components: {
    VuePerfectScrollbar,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    CancelShipmentsButton,
    TableFilter,
    ShipbluPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadMerchantShipments()
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
