<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeShipmentsModal"
    :active.sync="uploadModal"
    :title="$route.name === 'finance-instant-payouts' ? $t('Upload Instant Payouts') : $t('Upload Bank Deposits')"
    :buttons-hidden="true">
    <div class="text-center mt-5">
      <vs-button @click="getTemplate()">{{$route.name === 'finance-instant-payouts' ? $t('Download requested payouts') : $t('Download unsettled statements')}}</vs-button>
    </div>
    <div class="excel-import">
      <input type="file" ref="fileInput" class="hidden" accept=".xlsx, .xls" @change="handleClick">
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="w-1/2 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
        <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
        <span>{{$t('Drop Excel File or')}} </span>
        <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
      </div>
    </div>
    <div v-if="file" class="w-4/5 m-auto text-center mb-3">
      <vs-progress :percent="progress" color="primary"></vs-progress>
      <p>{{progress}}%</p>
    </div>
  </shipblu-prompt>
</template>

<script>
import AXIOS from '../../../axios.js'
import common from '../../../assets/utils/common'
import i18nData from '../../../i18n/i18nData'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import axios from 'axios'

export default {
  props: ['uploadModal'],
  data () {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      progress: 0,
      file: '',
      file_URl: ''
    }
  },
  methods: {
    getTemplate () {
      axios({
        url: this.$route.name === 'finance-instant-payouts' ? `${process.env.VUE_APP_API_URL}/api/v1/instant/payouts/upload/` : `${process.env.VUE_APP_API_URL}/api/v1/billing/bank-deposits/upload/`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.$route.name === 'finance-instant-payouts' ? 'pending_instant_payouts.xlsx' : 'bank-deposits.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch((error) => {
        common.notifyResponseError(this, error)
        this.$vs.loading.close()
      })
    },
    uploadFile (file) {
      if (!this.isExcel(file)) {
        this.$vs.notify({
          color: 'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          position: 'top-center'
        })
        return false
      }
      this.$refs['fileInput'].value = null // fix can't select the same excel
      this.progress = 0
      this.file = true
      const formData = new FormData()
      if (this.$route.name === 'finance-instant-payouts') {
        formData.append('pending_instant_payouts', file)
      } else {
        formData.append('unpaid_statements', file)
      }
      if (!this.isOnline) this.uploadShipmentsModal = false
      this.myProgress = setInterval(() => {
        this.progress += 1
      }, 500)
      AXIOS.post(this.$route.name === 'finance-instant-payouts' ? 'api/v1/instant/payouts/upload/' : 'api/v1/billing/bank-deposits/upload/', formData, {headers: this.headers})
        .then((response) => {
          this.progress = 100
          clearInterval(this.myProgress)
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.$emit('loadShipments')
          this.closeShipmentsModal()
          this.closeInterval = setInterval(() => {
            this.uploadShipmentsModal = false
            this.file = false
          }, 700)
        })
        .catch((error) => {
          this.progress = 100
          clearInterval(this.myProgress)
          common.notifyResponseError(this, error)
        })
    },
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
      this.uploadFile(rawFile)
    },
    isExcel (file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    closeShipmentsModal () {
      this.$emit('uploadModal', false)
      this.progress = 0
      this.file = false
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>