<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <h2>{{$t('Merchant Statements')}}</h2>
        </div>
      </div>
      <div class="vx-row" v-if="$store.state.AppActiveUser.userRole !== 'finance'">
        <div class="vx-col w-full lg:w-full mb-base">
          <cash-on-way :summaryCashamount="summaryCashamount"></cash-on-way>
        </div>
      </div>
      <shipblu-table
        :sst="true"
        orders
        multiple
        v-model="selected"
        pagination
        :max-items="maximumItems"
        :data="statements"
        :tableLoader="tableLoader"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
        </div>
        <template slot="thead">
          <shipblu-th>{{$t('Statement Date')}}</shipblu-th>
          <shipblu-th>{{$t('Statement Number')}}</shipblu-th>
          <shipblu-th>{{$t('Balance Due')}}</shipblu-th>
          <shipblu-th>{{$t('Cash Collection Balance')}}</shipblu-th>
          <shipblu-th>{{$t('Payout')}}</shipblu-th>
          <shipblu-th>{{$t('Status')}}</shipblu-th>
          <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'finance'">{{$t('Accounting Status')}}</shipblu-th>
          <shipblu-th>{{$t('Statement PDF')}}</shipblu-th>
          <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'">{{$t('Actions')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
            <shipblu-td :data="data[indextr].id">
              <span class="text-darkblue">{{ new Date(data[indextr].created).toLocaleDateString('fr-CA')}}</span>
            </shipblu-td>

            <shipblu-td :data="data[indextr].number">
              {{ data[indextr].number }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].net_service_fees_due">
              {{ data[indextr].v2 ? Number(data[indextr].v2.balance_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_service_fees_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].net_cod_due">
              {{ data[indextr].v2 ? Number(data[indextr].v2.cash_closing_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].net_cod_due">
              {{ data[indextr].v2 ? Number(data[indextr].v2.payout).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`" style="white-space: nowrap;">
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
              </div>
            </shipblu-td>

            <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'finance'" :data="data[indextr].accounting_status" :style="`color: ${getAccountStatusColor(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
              <div class="font-semibold">
                <span class="capitalize">{{ data[indextr].accounting_status }}</span>
              </div>
            </shipblu-td>

            <shipblu-td :data="data[indextr].pdf_link">
              <a @click="downloadStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('PDF')}}</a> / 
              <a @click="downloadExcelStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('Excel')}}</a> 
            </shipblu-td>
            
            <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="generatePDF(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="FilePlusIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Generate PDF')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="newStatement('Edit', data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="deleteStatement(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    </div>
    <add-statement @file_url="file_url = $event" @loadStatements="loadStatements"
    :type="type" :statementModal="statementModal" @statementModal="statementModal = $event" :statement="statement" @addStatement="addStatement"
    @updateStatement="updateStatement"></add-statement>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData'
import AddStatement from './AddStatement.vue'
import Discounts from './Discounts.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import CashOnWay from '../../merchant/components/CashOnWay.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      statements: [],
      selected: [],
      deleteData: {},
      statementModal: false,
      type: 'Add',
      discountType: 'Add',
      statement: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      file_URl: '',
      statementData: {},
      tableLoader: false,
      summaryCashamount: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStatements()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStatements()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getAccountStatusColor (order) {
      return common.getOrderStatusColor(order.accounting_status)
    },
    loadStatements () {
      this.tableLoader = true
      const query = `&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/statements/?merchant=${this.$route.params.merchantID}${query}`, 'get', null, true,
        (response) => {
          this.statements = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    downloadStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/excel/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    generatePDF (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'post', {}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['New PDF has been generated successfully. Click download!'],
            position: 'top-center'
          })
        }
      )
    },
    newStatement (type, data) {
      this.type = type
      if (type === 'Add') {
        this.statement = {
          number: '',
          net_service_fees_due: '',
          net_cod_due: '',
          status: '',
          accounting_status: '',
          expected_net_cod_deposit_date: '',
          pdf_link: '',
          merchant: '',
          discount: ''
        }
      } else {
        this.statement = data
      }
      this.statementModal = true
    },
    addStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.$route.params.merchantID
      }
      sendRequest(false, false, this, 'api/v1/billing/statements/', 'post', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    updateStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.$route.params.merchantID,
        opening_balance: this.statement.opening_balance
      }
      sendRequest(false, false, this, `api/v1/billing/statements/${this.statement.id}/`, 'patch', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    deleteStatement (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/statements/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadStatements()
        }
      )
    },
    summaryCash () {
      sendRequest(true, false, this, `api/v1/billing/statements/summary/?merchant_id=${this.$route.params.merchantID}`, 'get', null, true,
        (response) => {
          this.summaryCashamount = response.data
        }
      )
    }
  },
  components: { 
    AddStatement,
    Discounts,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    CashOnWay,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
    if (this.$store.state.AppActiveUser.userRole !== 'finance') this.summaryCash()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
