<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <h2>{{$t('Merchant Invoices')}}</h2>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="invoices"
          :tableLoader="tableLoader"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('Invoice Date')}}</shipblu-th>
            <shipblu-th>{{$t('Invoice Number')}}</shipblu-th>
            <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
            <shipblu-th>{{$t('Subtotal')}}</shipblu-th>
            <shipblu-th>{{$t('VAT')}}</shipblu-th>
            <shipblu-th>{{$t('Total')}}</shipblu-th>
            <shipblu-th>{{$t('Link to Order')}}</shipblu-th>
            <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'sales'">{{$t('Download Invoice')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].created">
                {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].number">
                {{ data[indextr].number }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].bill_to_customer_name">
                {{ data[indextr].bill_to_customer_name }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].subtotal">
                {{ Number(data[indextr].subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].vat">
                {{ Number(data[indextr].vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].total">
                {{ Number(data[indextr].total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].order">
                <router-link v-if="(data[indextr].invoice_exchange && data[indextr].invoice_exchange.length > 0) || (data[indextr].invoice_cash_collection && data[indextr].invoice_cash_collection.length > 0) || (data[indextr].invoice_order && data[indextr].invoice_order.length > 0)" :to="{
                  name: 
                    `${$store.state.AppActiveUser.userRole}-${data[indextr].invoice_exchange.length > 0 ? 'exchanges' 
                    : data[indextr].invoice_cash_collection.length > 0 ? 'cash-collections' 
                    : data[indextr].invoice_order[0].order_type}`, 
                  params: { type: `${data[indextr].invoice_exchange.length > 0 ? 'exchanges' 
                    : data[indextr].invoice_cash_collection.length > 0 ? 'cash-collections' 
                    : data[indextr].invoice_order[0].order_type}`, 
                    orderID: data[indextr].invoice_exchange.length > 0 ? data[indextr].invoice_exchange[0].exchange 
                    : data[indextr].invoice_cash_collection.length > 0 ? data[indextr].invoice_cash_collection[0].order 
                    : data[indextr].invoice_order[0].order ? data[indextr].invoice_order[0].order : ''}
                }">
                  {{$t('Open in a new tab')}}
                </router-link>
              </shipblu-td>

              <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'sales'" :data="data[indextr].pdf_link">
                <a @click="downloadInvoice(data[indextr])" target="_blank">{{$t('Download')}}</a>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      invoices: [],
      selected: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      file_URl: '',
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadInvoices()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadInvoices()
    },
    downloadInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    loadInvoices () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/merchants/${this.$route.params.merchantID}/invoices/${query}`, 'get', null, true,
        (response) => {
          this.invoices = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadInvoices()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
