<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="bankDepositModal"
    :title="$t(type) + ' ' + $t('Bank Deposit')"
    :buttons-hidden="true" autocomplete="nofill">
      <div>
        <input name="file" type="file" @change="changeImage" class="hidden" ref="fileInput">
        <div
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="w-4/5 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <div v-if="image === ''">
            <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
            <span>{{$t('Drop File or')}} </span>
            <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
          </div>
          <div v-else>
            <img class="w-20 h-20" :src="image"/>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="bank reference number" :label-placeholder="$t('Bank Reference Number') + '*'" v-model="bankDeposit.bank_reference_number" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('bank reference number')">{{ errors.first('bank reference number') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|decimal'" name="amount" :label-placeholder="$t('Amount') + '*'" v-model="bankDeposit.amount" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <datepicker v-validate="'required'" name="deposit date" :placeholder="$t('Deposit Date') + '*'" v-model="bankDeposit.deposit_date"  class="mt-8 w-full"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('deposit date')">{{ errors.first('deposit date') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="statement" v-model="bankDeposit.statement" 
          v-validate="'required'" :placeholder="$t('Statement') + '*'" label="number" :options="statements"/>
          <span class="text-danger text-sm" v-show="errors.has('statement')">{{ errors.first('statement') }}</span>
        </div>
        <div class="vx-col w-1/2" v-if="!$route.params.merchantID">
          <v-select autocomplete="nofill" :disabled="true" class="select-large mt-8 w-full" name="merchant" v-model="bankDeposit.merchant" 
          v-validate="'required'" :placeholder="$t('Merchant') + '*'" label="name" :options="merchants"/>
        </div>
        <div class="vx-col w-1/2" v-if="!$route.params.merchantID && type === 'Edit'">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="status" v-model="bankDeposit.status" 
            v-validate="'required'" :placeholder="$t('Status') + '*'" label="name" :options="statuses"/>
            <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'Add'" @click="addBankDeposit" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updateBankDeposit" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'

export default {
  props: ['bankDepositModal', 'type', 'bankDeposit', 'statements', 'merchants'],
  data () {
    return {
      image: '',
      file_key: '',
      statuses: [
        {
          name: 'Deposited',
          value: 'deposited'
        },
        {
          name: 'Rejected',
          value: 'rejected'
        }
      ]
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    Datepicker
  },
  watch: {
    'bankDeposit.deposit_date' (val) {
      this.bankDeposit.deposit_date = common.formatDate(val)
    },
    'bankDeposit.statement' (val) {
      if (val) {
        this.bankDeposit.merchant = val.merchant
      }
    },
    type () {
      if (this.type === 'Edit') {
        this.image = this.bankDeposit.deposit_slip_key
      } else {
        this.bankDeposit.deposit_date = ''
        this.image = ''
      }
    }
  },
  methods: {
    changeImage () {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.image = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', this.$refs.fileInput.files[0])
      sendRequest(false, false, this, 'api/v1/upload-bank-deposit-file/', 'post', formData, true,
        (response) => {
          this.file_key = response.data.file_key
          this.$emit('file_key', this.file_key)
        }
      )
    },
    closeModal () {
      this.$emit('bankDepositModal', false)
      this.$emit('loadBankDeposits')
    },
    addBankDeposit () {
      this.$validator.validateAll().then(result => {
        if (result && this.bankDeposit.deposit_date) {
          const address = { 
            bank_reference_number: this.bankDeposit.bank_reference_number,
            amount: this.bankDeposit.amount,
            deposit_date: this.bankDeposit.deposit_date,
            statement: this.bankDeposit.statement.id,
            deposit_slip_key: this.file_key
          }
          sendRequest(false, false, this, 'api/v1/billing/bank-deposits/', 'post', address, true,
            () => {
              this.$emit('bankDepositModal', false)
              this.image = ''
              this.$emit('loadBankDeposits')
            }
          )
          
        }
      })
    },
    updateBankDeposit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const address = { 
            bank_reference_number: this.bankDeposit.bank_reference_number,
            amount: this.bankDeposit.amount,
            deposit_date: this.bankDeposit.deposit_date,
            statement: this.bankDeposit.statement.id,
            merchant: this.bankDeposit.statement.merchant.id,
            deposit_slip_key: this.file_key,
            status: this.bankDeposit.status.value
          }
          sendRequest(false, false, this, `api/v1/billing/bank-deposits/${this.bankDeposit.id}/`, 'patch', address, true,
            () => {
              this.$emit('bankDepositModal', false)
              this.$emit('loadBankDeposits')
            }
          )
        }
      })
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  }
}
</script>